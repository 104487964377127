import React from 'react';
import { Global, css } from '@emotion/core';
import Helmet from 'react-helmet';
import Header from './header';
import artHead from '../../images/ARTPOSTS.svg';
import fbIcon from '../../images/fb_logo.svg';
import twIcon from '../../images/tw_logo.svg';
import igIcon from '../../images/ig_logo.svg';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import useSiteMetadata from '../hooks/useSiteMetadata';

const ImageBackground = styled(BackgroundImage)`
  background-position: top 50% center;
  background-size: cover;
  height: 100vh;
  /* override the default margin for sibling elements  */
  + * {
    margin-top: 0;
  }
`;

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "mondrianBG.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }
          /* More info: https://bit.ly/2PsCnzk */
          * + * {
            margin-top: 1rem;
          }
          html,
          body {
            margin: 0;
            color: #555;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol';
            font-size: 18px;
            line-height: 1.4;
            /* remove margin for the main div that Gatsby mounts into */
            > div {
              margin-top: 0;
            }
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: #222;
            line-height: 1.1;
            + * {
              margin-top: 0.5rem;
            }
          }
          strong {
            color: #222;
          }
          li {
            margin-top: 0.25rem;
          }
          .social {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
          @media (min-width: 460px) {
            .wrapper {
            }
          }
          .grid {
            width: 100vw;
          }
        `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>{' '}
      <ImageBackground
        className="wrapper"
        Tag="section"
        fluid={image.sharp.fluid}
      >
        <div className="grid">
          <>
            <Header className="headnav"></Header>
            <main
              css={css`
                background: rgb(255, 255, 255, 0.6);
                border: 3px solid #2599c7;
                border-radius: 12px;
                padding: 1rem;
              `}
            >
              {children}
            </main>
            <div
              css={css`
                margin-top: -2rem;
                padding: 1rem;
              `}
              className="social"
            >
              <a href="http://facebook.com/greenlayk">
                <img
                  style={{
                    marginTop: '16px',
                    marginRight: '12px',
                    minWidth: '6vw',
                  }}
                  src={fbIcon}
                  alt="facebook link"
                />
              </a>
              <a href="http://twitter.com/greenlayk">
                <img
                  style={{
                    width: '7.5vw',
                    marginRight: '12px',
                    padding: '6px',
                  }}
                  src={twIcon}
                  alt="twitter link"
                />
              </a>
              <a href="http://instagram.com/greenlayk">
                <img
                  style={{
                    minWidth: '6.5vw',
                    marginRight: '10px',
                    padding: '2px',
                  }}
                  src={igIcon}
                  alt="instagram link"
                />
              </a>
            </div>
          </>
        </div>
      </ImageBackground>
    </>
  );
};

export default Layout;
