import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout-about';

export default () => (
  <Layout>
    <>
      <h1>About a creative</h1>
      <p>Art works and other creative musings from greenlayk</p>
      <Link to="/">&larr; Back to Homepage</Link>
    </>
  </Layout>
);
